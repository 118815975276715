import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Logo = createSvgIcon(
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 171.41 146.67"
    fill="#1695ea"
  >
    <path
      fillRule="evenodd"
      d="M65.64,172l28.11-37.1h25.87c6.16,0,13.4,0,18.23.43-1.54-5.18-3.74-14-5.48-20.92L123,76.23,51.78,172H14.05L111.28,41.5c6.32-8.41,14.49-16.18,24.5-16.18,9.55,0,14.16,7.12,16.47,16.18L185.46,172Z"
      transform="translate(-14.05 -25.32)"
      clipRule="evenodd"
    />
  </svg>,
  'Logo'
);

export default Logo;
