import type { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';
import { setRequestedLocation } from 'src/slices/user';
import { useDispatch, useSelector } from 'src/store';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const { requestedLocation } = useSelector((state) => state.user);
  console.log(auth);
  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      dispatch(setRequestedLocation(location.pathname));
    }
    return <Login />;
  }
  // if (loadingStatus !== LoadingStatus.COMPLETED) {
  //   if (loadingStatus === LoadingStatus.NONE) {
  //     let subdomain = domainName;
  //     if (!subdomain) {
  //       if (process.env.NODE_ENV === 'production') {
  //         const domain = window.location.hostname;
  //         if (domain !== 'go.accziom.com') {
  //           const idx = domain.indexOf('.go.accziom.com');
  //           if (idx <= 0) return <Navigate to="/error" />;
  //           subdomain = domain.substring(0, idx);
  //         }
  //       }
  //     }
  //     initialLoad(auth.user.id, auth.checkLive, dispatch, auth.logout, subdomain);
  //   }
  //   return <SpinnerScreen />;
  // }
  if (requestedLocation && location.pathname !== requestedLocation) {
    dispatch(setRequestedLocation(location.pathname));
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
