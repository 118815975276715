import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Category } from 'src/../../Common/Model/ontology';

interface CategoryState {
  categories: Category[];
  currentCategory: Category;
  targetParent: Category;
  needReload: boolean;
  isNew: boolean;
}

const initialState: CategoryState = {
  categories: [],
  currentCategory: null,
  targetParent: null,
  needReload: true,
  isNew: true,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    clearState(state: CategoryState) {
      state.categories.splice(0, state.categories.length);
      state.needReload = true;
    },
    setNeedReload(state: CategoryState, action: PayloadAction<boolean>): void {
      state.needReload = action.payload;
    },
    setNew(state: CategoryState, action: PayloadAction<boolean>): void {
      state.isNew = action.payload;
    },
    setCategories(state: CategoryState, action: PayloadAction<{ categories: Category[]; }>) {
      const { categories } = action.payload;
      state.categories = categories.map((c) => ({ ...c, uid: `${c.uid}` }));
      state.needReload = false;
    },
    addCategories(state: CategoryState, action: PayloadAction<{ categories: Category[]; }>) {
      const { categories } = action.payload;
      state.categories.push(...categories);
    },
    setCurrentCategory(state: CategoryState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      state.currentCategory = state.categories.find((c) => c.uid === id) ?? null;
    },
    setTargetParent(state: CategoryState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const current = state.categories.find((c) => c.uid === id);
      state.targetParent = state.categories.find((c) => c.uid === current?.parent) ?? null;
    },
    updateCategory(state: CategoryState, action: PayloadAction<any>): void {
      const { categoryId, props } = action.payload;
      state.categories.forEach((category) => {
        if (category.uid === categoryId) {
          Object.keys(props).forEach((key) => {
            category[key] = props[key];
          });
        }
      });
    },
    updateRawCategory(state: CategoryState, action: PayloadAction<Category>): void {
      const updated = action.payload;
      const category = state.categories.find((item) => item.uid === updated.uid);
      if (category) {
        Object.keys(updated).forEach((key) => {
          if (key !== 'uid') category[key] = updated[key];
        });
      } else state.categories.push(updated);
    },
    removeCategory(state: CategoryState, action: PayloadAction<{ uid: string; }>) {
      const { uid } = action.payload;
      const index = state.categories.findIndex((item) => item.uid === uid);
      state.categories.splice(index, 1);
    },
  }
});

export const { reducer } = slice;

export const clearCategoryState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNeedReload(needReload));
};

export const setNew = (flag: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNew(flag));
};

export const loadCategories = (categories: Category[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCategories({ categories }));
};
export const addCategories = (categories: Category[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addCategories({ categories }));
};
export const updateCategory = (categoryId: string, props: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateCategory({ categoryId, props }));
};
export const updateRawCategory = (category: Category): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateRawCategory(category));
};
export const setTargetParent = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTargetParent({ id }));
};
export const setCurrentCategory = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentCategory({ id }));
};
export const removeCategory = (uid : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeCategory({ uid }));
};

export default slice;
