import { combineReducers } from '@reduxjs/toolkit';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as categoryReducer } from 'src/slices/category';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';

const rootReducer = combineReducers({
  user: userReducer,
  category: categoryReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer
});

export default rootReducer;
